<template>
  <div class="page-account-review page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ title }}</b></p>
      <p>{{ description }}</p>
    </KlubbaRichText>

    <div class="container">
      <klubba-card-review
        :firstName="first_name"
        :lastName="last_name"
        :phoneNumber="phone_number"
      />
    </div>

    <div class="button-wrap">
      <klubba-button
        class="mb-4"
        :childClass="childClass"
        :icon="icon"
        :iconClass="iconClass"
        theme="black"
        tag="a"
        :loading="!user?.enrollment?.id"
        :href="`https://pub1.pskt.io/${user?.enrollment?.id}`"
        target="_blank"
        @click="handleFinishedButton"
      />
      <klubba-button
        :buttonText="isNewUser ? 'Skip downloading Klubba card' : 'I already have my Klubba card'"
        @click="handleFinishedButton"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert';

import myfirebase from '@/config/firebase';
import KlubbaButton from '@/components/default/KlubbaButton.vue';
import KlubbaCardReview from '@/components/custom/KlubbaCardReview.vue';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';
import setOrUpdateRef from '../../tools/setOrUpdateRef';

export default {
  components: {
    KlubbaRichText,
    KlubbaButton,
    KlubbaCardReview,
  },
  data() {
    return {
      childClass: 'flex text-white px-2',
      icon: 'apple-wallet.svg',
      iconClass: 'h-11',
      isNewUser: true,
    };
  },
  computed: {
    ...mapGetters(['user', 'platform', 'club', 'firebaseUser']),
    title() {
      return this.isNewUser ? 'Your account has been set up!' : 'Welcome back to Klubba';
    },
    description() {
      return this.isNewUser ? 'Download your wallet pass to your phone. This is your digital membership card.' : 'You are already an active Klubba user. You can re-download your Klubba card below or skip to the next step';
    },
    first_name() {
      return this.$store.getters.getUserKey('first_name');
    },
    last_name() {
      return this.$store.getters.getUserKey('last_name');
    },
    phone_number() {
      return this.$store.getters.getUserKey('phone_number');
    },
  },
  mounted() {
    if (this.user?.enrollment) {
      this.isNewUser = false;
    }
    this.createUser();
  },
  methods: {
    handleFinishedButton() {
      this.$nextTick(() => {
        this.$router.push(`/${this.club.id}/add-wallet`);
      });
    },
    async createUser() {
      const db = myfirebase.firestore();
      const userRef = db.collection('users').doc(this.firebaseUser.uid);
      const userData = this.$store.getters.newUser;
      const clubSpecificRef = db.collection('users').doc(this.firebaseUser.uid).collection('club_specific').doc(this.club.id);
      const clubSpecificData = this.$store.getters.newUserClubSpecific;

      // Create/update user
      setOrUpdateRef(userRef, userData).then(async ({ action }) => {
        if (action === 'set') swal('Success', 'User created successfully!', 'success');

        // Create/update club specific
        await setOrUpdateRef(clubSpecificRef, clubSpecificData).catch(({ error }) => {
          swal('Error', error.message, 'error');
        });
      }).catch(({ error }) => {
        swal('Error', error.message, 'error');
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8

.button-wrap
  @apply p-8 mt-auto
</style>
