<template>
  <div class="section-card-review">
    <!-- Header -->
    <div class="header">
      <p class="access-card">My KLUBBA membership account access card</p>
      <p class="active">ACTIVE</p>
    </div>

    <!-- Card -->
    <div class="card">
      <img src="@/assets/icons/logo.svg" class="logo-icon" />
      <p class="name">{{ firstName }} {{ lastName }}</p>
      <p class="phone">{{ phoneNumber }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['firstName', 'lastName', 'phoneNumber'],
};
</script>

<style lang="sass" scoped>
// Header
.header
  @apply rounded-radius-10 w-full flex items-center p-5 pb-7
  background: var(--klubba-primary-back-color)

.active
  @apply font-bold text-xl leading-6 tracking-spacing-5 ml-auto
  font-size: 21px
  color: var(--klubba-secondary-back-color)

.access-card
  @apply tracking-spacing-11 text-xs font-bold uppercase mr-4 flex-1
  max-width: 190px
  color: var(--klubba-primary-fore-color)

// Card
.card
  @apply -mt-2 -mx-1 p-5 rounded-radius-10 pb-4
  box-shadow: 0px -4px 8px rgba(black, .4)
  background: var(--klubba-secondary-back-color)

.logo-icon
  @apply mb-5
  width: 92px

.name
  @apply leading-6 tracking-spacing-5 uppercase break-words
  font-family: 'Courier New', monospace
  font-size: 22px
  color: var(--klubba-secondary-fore-color)

.phone
  @apply leading-5 tracking-spacing-8
  color: var(--klubba-primary-back-color)
</style>
